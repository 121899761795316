import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
// import { Location } from '@reach/router';
import Img from "gatsby-image";

import Layout from "../components/layout";
import Title from "../components/title";
import Inview from "../components/inview";

export default function EcSolutionSivision({ data }) {
  const divStyle2 = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  };
  const divStyle3 = {
    padding: "150% 0 0 0",
    position: "relative",
  };
  const pageData = {
    titleClass: "logistics",
    title: "物流機器事業本部",
    discription: "",
    image: 12,
  };
  const seodata = {
    title: "Logistics Equipment Division アヴィエラン株式会社　物流機器事業部",
    seo: {
      description: "物流の現場で使用されるスリングベルト・ラッシングベルトなどの資材の製造、販売をしております。",
      image: {
        url: data.datoCmsImage.topimage[12].url,
      },
    },
    slug: "logisticsequipment",
  };
  return (
    <Layout pagestyle={pageData} seo={seodata}>
      <Title titlestyle={pageData} />
      <div className="pankz">
        <Link to="/">トップ</Link>
        <p>物流機器事業本部</p>
      </div>
      <div className="l_page_contents">
        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.topimage[27].news} alt="アヴィエラン株式会社　物流機器事業部" />
            <div className="lp_movie">
              <div style={divStyle3}>
                <iframe src="https://player.vimeo.com/video/565968531?background=1?muted=1?autoplay=1&loop=1&title=0&byline=0&portrait=0" style={divStyle2} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="nagoyako"></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
            <Inview c_name="title" tag="p">
              物流資材に革新を
              <span>Innovation in logistics materials</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              物流の現場で使用されるスリングベルト・ラッシングベルトなどの資材の製造、販売をしております。 また既存の資材だけではなく、日々お客様のニーズに沿った特注品の製造・新商品の開発を行い、 新たな事業展開を目指しております。
            </Inview>
            <div className="freedial">
              <p>物流のお問合せはこちら</p>
              <Img fluid={data.datoCmsImage.topimage[26].free} alt="フリーダイヤル" />
              <span className="pctel">0120-947-576</span>
              <a className="sptel" href="tel:0120947576">
                0120-947-576
              </a>
            </div>
            <div className="freedial">
              <p>WEB CATALOGはこちら</p>
              <a className="webcatalog" href="https://avielan.slingbelt.com/catalog/" target="_blank" rel="noreferrer">
                WEB CATALOG
              </a>
            </div>
          </div>
        </div>

        <div className="box1 ">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.productsImg[16].item} alt="吊り具の定番、スリングベルト" />
            <Inview c_name="title" tag="h2">
              吊り具の定番、スリングベルト
              <span>A classic hanging tool</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              重量物や作業員に優しい吊り具。（例えば、軽金属品、鉄鋼材、機械、石材、鋼菅、プラスチック製品、コンテナ、電気製品等）繊維の柔らかいベルトで重量物を吊り上げることができます。吊荷を傷つけたくない吊り作業、 あらゆる荷役作業に大活躍します。
            </Inview>
            <Link to="/slingbelt/">
              <span>詳細はこちら</span>
            </Link>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.productsImg[23].item} alt="ソフトな吊り具、ラウンドスリング" />
            <Inview c_name="title" tag="h2">
              ソフトな吊り具、ラウンドスリング
              <span>A soft hanging tool</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              どんな荷姿にも自在にフィットし、安定性も抜群です。 内側は強力ポリエステル繊維を束ね、表面布で覆った形状の柔らかいスリング です。軽くてソフトな材質で吊りにくい荷姿にも柔軟になじみ、滑りも少なく吊り荷をキズつけることなく安心してご使用頂けます。
            </Inview>
            <Link to="/roundsling/">
              <span>詳細はこちら</span>
            </Link>
          </div>
        </div>
        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.productsImg[11].item} alt="固縛作業の強い味方 ラッシングベルト" />
            <Inview c_name="title" tag="h2">
              固縛作業の強い味方 ラッシングベルト
              <span>Strong ally for lashing work</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              あらゆる荷物の固定・荷崩れ防止に使用する、やさしくがっちりと固定するラチェットバックル式ベルトです。 材質は摩耗に強いポリエステル製で、耐水性、耐候性に優れています。 小さな力で大きな荷締めでも簡単・スピーディーに固縛し作業効率UP！
            </Inview>
            <Link to="/lashingbelt/">
              <span>詳細はこちら</span>
            </Link>
          </div>
        </div>
        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.productsImg[32].item} alt="誰でも簡単・手軽に使える、25㎜幅ベルト" />
            <Inview c_name="title" tag="h2">
              誰でも簡単・手軽に使える、25㎜幅ベルト
              <span>A soft hanging tool</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              パレットの固縛や倉庫内作業における簡易固定、棚からの落下防止等に多用されています。安価でもしっかり荷物を固定できる優れものの荷締めベルト。
            </Inview>
            <Link to="/cum-buckle/">
              <span>詳細はこちら</span>
            </Link>
          </div>
        </div>

        <div className="box1 ">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.topimage[15].news} alt="アヴィエラン株式会社　物流機器事業部" />
            <Inview c_name="title" tag="p">
              公式通販サイトフルリニューアル
              <span>Official order site</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              物流業界で幅広く使用されているスリングベルト・ラッシングベルト・特殊ゴム製品の販売サイトになります。 既存商品は即日出荷で対応させていただいております。 また特注品の製造も承っております。
            </Inview>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.topimage[14].news} alt="アヴィエラン株式会社　物流機器事業部" />
            <Inview c_name="title" tag="p">
              ニーズに沿った特注品の製作
              <span>Custom-made products</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              特殊なフックや長さであったり、通常販売されていない仕様のベルトのご注文も承っております。
            </Inview>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.topimage[16].news} alt="アヴィエラン株式会社　物流機器事業部" />
            <Inview c_name="title" tag="p">
              ベルト印字サービス
              <span>Printing service</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              弊社ラッシングベルト 各種をご購入の際、アナログ印刷で御社の社名やロゴをベルトに印刷するサービスを開始しました。アナログ印刷で見やすくはっきりとベルトに社名を印刷します。
            </Inview>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.topimage[13].news} alt="アヴィエラン株式会社　物流機器事業部" />
            <Inview c_name="title" tag="p">
              お客様限定支援サービス
              <span>Support service</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              御社が販売している製品をスリングベルト.comで販売してみませんか？ スリングベルト.com内に、販売コーナーを設け販売のお手伝いを致します。 ※初期費用・出品手数料無料
            </Inview>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    datoCmsImage {
      topimage {
        url
        news: fluid(maxWidth: 1200, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop", w: "600", h: "600" }) {
          ...GatsbyDatoCmsFluid
        }
        free: fluid(maxWidth: 1200, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop", w: "248", h: "142" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      productsImg {
        item: fluid(maxWidth: 1200, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop", w: "600", h: "600" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;
